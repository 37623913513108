import { template as template_d124a0b34e47464fb72b988f938c0e39 } from "@ember/template-compiler";
const FKLabel = template_d124a0b34e47464fb72b988f938c0e39(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
