import { template as template_5f29e7fd29574ced89848e45fbde60ff } from "@ember/template-compiler";
const FKControlMenuContainer = template_5f29e7fd29574ced89848e45fbde60ff(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
