import { template as template_1286d9dad89a489cb63921a50db14761 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1286d9dad89a489cb63921a50db14761(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
